"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const unsafe = /[\u{0000}-\u{0008}\u{D800}-\u{DFFF}\u{FFFE}-\u{FFFF}]/um;
function sanitize(input = '', replacement = '') {
    return input.replace(unsafe, replacement);
}
exports.sanitize = sanitize;
function validate(input) {
    return sanitize(input).length === input.length;
}
exports.validate = validate;
